@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .flex_row {
        @apply flex flex-row
    }

    .flex_col {
        @apply flex flex-col
    }

    .flex_row-center {
        @apply flex flex-row items-center justify-center
    }

    .flex_row-center-x {
        @apply flex flex-row justify-center
    }

    .flex_row-center-y {
        @apply flex flex-row items-center
    }

    h1 {
        @apply text-3xl md:text-4xl font-playfair font-medium
    }

    .btn {
        @apply px-3 py-2 bg-primary rounded-md font-barlow font-medium text-white
    }
}

.gradient {
    background: linear-gradient(99.71deg, #13A89E -27.2%, #0F7972 48.63%, #13A59B 105.87%);
}

* {
    font-family: "Barlow", sans-serif;
}

.nav .BurgerMenu_container {
    display: flex;
    flex-direction: column;
}

.nav i {
    background-color: rgb(78, 78, 78);
    width: 32px;
    height: 4px;
    margin: 4px;
    border-radius: 2px;
    transition: all 0.4s ease-in-out;
}

.nav .open:nth-child(1) {
    transform: rotate(45deg) translateY(16px);
}

.nav .open:nth-child(2) {
    opacity: 0;
}

.nav .open:nth-child(3) {
    transform: rotate(-45deg) translateY(-16px);
}

.nav .close:nth-child(1) {
    transform: rotate(0) translateY(0);
}

.nav .close:nth-child(2) {
    opacity: 1;
}

.nav .close:nth-child(3) {
    transform: rotate(0) translateY(0);
}

.nav .nav-tab {
    transition: all 0.5s ease-in-out;
}

/* SCROLL SNAP EFFECT:DESKTOP VIEW  */

/* END OF SCROLL SNAP EFFECT:DESKTOP VIEW */

@media (min-width: 768px) {
    .nav .BurgerMenu_container {
        display: none;
    }
}
